import { TypographyH1 } from "@/components/ui/typography";

const TextElement = ({ ref }: { ref: React.Ref<HTMLDivElement> }) => (
  <div ref={ref} className="flex-shrink-0 animate-slide-loop whitespace-nowrap">
    <TypographyH1 className="text-[10rem] lg:text-[20rem] leading-[.8]">
      Talk_to_me_
    </TypographyH1>
  </div>
);

TextElement.displayName = "rolling-text-element";

export default TextElement;
