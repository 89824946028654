"use client";

import { useEffect, useMemo, useRef, useState } from "react";
import { cn } from "@/lib/utils";

import TextElement from "./text-element";

const RollingText = () => {
  const [screenWidth, setScreenWidth] = useState(0);
  const rollingTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window?.innerWidth);
    }

    if (window === undefined) {
      return;
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Calculate the number of text copies necessary based on the screen width
  const textCopiesAmount = useMemo(() => {
    const textElementWidth = rollingTextRef.current?.offsetWidth ?? 1;

    // Allow no more than 15 text copies
    if (Math.ceil(screenWidth / textElementWidth) < 15) {
      return Math.ceil(screenWidth / textElementWidth);
    }
    return 15;
  }, [screenWidth]);

  return (
    <div
      className={cn(
        "h-adj-navbar relative w-full overflow-hidden m-auto flex items-end justify-start"
      )}
    >
      <TextElement ref={rollingTextRef} />
      {
        // The additional text copies are added here to fill the remaining space
        Array.from({ length: textCopiesAmount }).map((_, index) => (
          <TextElement key={index} ref={rollingTextRef} />
        ))
      }
    </div>
  );
};

export default RollingText;
